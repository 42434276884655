import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { useForm } from 'react-hook-form'
import { useSnackbar } from '../shared'
import { Link, navigateTo, varClass, responseError } from '../shared/helpers'
import { postRequest } from '../../services/api'

export const Forgot = (props) => {
  const { email, token } = props

  const [sentEmail, setSentEmail] = useState(email)

  const { showSnackbar } = useSnackbar()

  return (
    <div className="text-center p-y-90">
      <SVG src="/images/forgot-illustration.svg" />
      {!sentEmail ? (
        <ResetForm done={(values) => setSentEmail(values.email)} />
      ) : (
        <VerifyForm
          email={sentEmail}
          token={token}
          showSnackbar={showSnackbar}
        />
      )}
    </div>
  )
}

const ResetForm = (props) => {
  const { done } = props

  const form = useForm()
  const { register, handleSubmit } = form

  const onSubmit = async (values) => {
    const response = await postRequest('/auth/v1/password/reset/request', {
      userEmail: values.email,
    })

    if (response) {
      if (response.code !== 200) {
        console.error(responseError(response))
      }
    }

    done(values)
  }

  return (
    <div className="login-email-form m-t-7" style={{ maxWidth: 420 }}>
      <div className="h1 m-b-3">Forgot Password?</div>
      <div className="text-light text-bold o-70 m-b-40">
        No worries, we will send you reset instructions
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="text-left">
        <div className="control m-b-8">
          <label>Email</label>
          <input
            type="email"
            placeholder="mail@mailservice.com"
            {...register('email')}
          />
        </div>

        <button className="btn btn-primary m-t-40" type="submit">
          Reset password
        </button>
      </form>

      <div className="text-light text-big text-bold m-t-40">
        <Link href="/login" className="link link-secondary vertical-middle">
          <SVG src="/images/arrow-back.svg" className="m-r-3" />
          Back to login
        </Link>
      </div>
    </div>
  )
}

const VerifyForm = (props) => {
  const { email, token, showSnackbar } = props

  const form = useForm({
    defaultValues: {
      token: token || '',
      password: '',
    },
  })
  const { register, handleSubmit } = form

  const onSubmit = async (values) => {
    const response = await postRequest('/auth/v1/password/reset', {
      userEmail: email,
      userPassword: values.password,
      resetToken: values.token,
    })

    if (response) {
      if (response.code !== 200) {
        showSnackbar(responseError(response))
        return
      }
    }

    navigateTo('/login')
  }

  return (
    <div className="login-email-form m-t-7" style={{ maxWidth: 420 }}>
      <div className="h1 m-b-3">Forgot Password?</div>
      <div className="text-light text-bold o-70 m-b-40">
        Check your email! We've sent you a reset token.
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="text-left">
        <div
          className={varClass({
            'control m-b-8': true,
            'hidden': !!token,
          })}
        >
          <label>Reset token</label>
          <input type="text" {...register('token')} />
        </div>

        <div className="control m-b-8">
          <label>New password</label>
          <input
            type="password"
            autoComplete="new-password"
            {...register('password')}
          />
        </div>

        <button className="btn btn-primary m-t-40" type="submit">
          Reset password
        </button>
      </form>

      <div className="text-light text-big text-bold m-t-40">
        <Link href="/login" className="link link-secondary vertical-middle">
          <SVG src="/images/arrow-back.svg" className="m-r-3" />
          Back to login
        </Link>
      </div>
    </div>
  )
}

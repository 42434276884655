import React from 'react'
import Helmet from 'react-helmet'
import {
  ExceptionHandler,
  ModalContextProvider,
  SnackbarContextProvider,
} from '../shared'
import { useAuth } from '../shared/hooks'

import '../../../static/stylesheets/bundle.scss'

export const Layout = (props) => {
  const authenticated = useAuth({
    redirectAuthenticated: '/',
  })

  if (authenticated === null || authenticated === true) {
    return null
  }

  return (
    <ExceptionHandler>
      <ModalContextProvider>
        <SnackbarContextProvider>
          <Head />
          <main className="login-layout">{props.children}</main>
        </SnackbarContextProvider>
      </ModalContextProvider>
    </ExceptionHandler>
  )
}

const Head = (props) => {
  return (
    <Helmet>
      <title>Login</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
  )
}

import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../components/layouts/login'
import { Forgot } from '../components/login/forgot'

const parseToken = (path: string): [string, string] => {
  if (path && path.includes('/')) {
    return path.split('/') as [string, string]
  } else {
    return ['', '']
  }
}

export default function LoginPage(props: PageProps) {
  const [email, token] = parseToken(props.params['*'] || '')

  return (
    <Layout>
      <Forgot email={email} token={token} />
    </Layout>
  )
}
